import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService implements CanActivate, CanActivateChild, OnInit, OnDestroy {

  private isAdmin: boolean;
  private admSub: Subscription;

  constructor(private router: Router,
    private auth: AuthService) { 
      this.admSub = this.auth.getAdmin().subscribe(
        (resp: any) => {
          this.isAdmin = resp;
        }
      );
  }

  ngOnInit() {
    this.auth.restoreAdmin();
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(
      (resolve, reject) => {
        if(this.isAdmin) {
          resolve(true);
        }else{
          this.router.navigate(['/']);
          resolve(false);
        }
      }
    );
    
  }

  canActivateChild(): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate();
  }
  
  ngOnDestroy() {
    if(this.admSub) this.admSub.unsubscribe();
  }

}
