import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { User, Role, RoleMapping } from '../../shared/sdk/models';
import { UserApi, RoleMappingApi, RoleApi } from '../../shared/sdk/services';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {

  public user: User = new User();
  private rms = new Array<any>();
  private trol = new Array<any>();
  private formSub: Subscription;
  private userSub: Subscription;
  private roleSub: Subscription;
  private cpass: Subscription;
  private userForm: FormGroup;
  private passForm: FormGroup;
  private passok: boolean = false;
  private passnok: boolean = false;
  private message: string;
  
  constructor(private location: Location,
              private builder: FormBuilder,
              private rapi: RoleApi,
              private rmapi: RoleMappingApi,
              private usapi: UserApi) { 
              
              this.userSub = this.usapi.getCurrent({include: 'roles'}).subscribe(
                (user: any) => {
                  this.user = user;
                  user.roles.forEach(element => {
                    this.trol.push(element.id.toString());
                  });
                  this.roleSub = this.rapi.find().subscribe(
                    (roles: Role[]) => {
                      roles.forEach(element => {
                        this.rms.push(element);
                      });
                    }
                  );
                  this.userForm.setValue({
                    email: this.user.email, 
                    id: this.user.id, 
                    username: this.user.username, 
                    realm: this.user.realm, 
                    roles: this.trol
                  }); 
                },
                (e) => {
                  console.dir(e);
                },
                () => {
                }
              );
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.userForm = new FormGroup(this.builder.group({
      id: [{value: this.user.id, disabled: true}],
      username: ['', Validators.required],
      realm: ['', Validators.required],
      email: [{value:'', disabled: true}],
      roles: [{value: '', disabled: true}]
    }).controls, {updateOn: 'change'});

    this.passForm = new FormGroup(this.builder.group({
      oldpass: ['', [Validators.required, Validators.pattern(/[0-9a-zA-Z]{8,}/)]],
      newpass: ['', [Validators.required, Validators.pattern(/[0-9a-zA-Z]{8,}/)]]
    }).controls, {updateOn: 'change'});
  }

  onSubmitUser() {
    if(this.userForm.get('id').value > 0 ) {
      this.user.id = this.userForm.get('id').value;
      this.user.username = this.userForm.get('username').value;
      this.user.realm = this.userForm.get('realm').value;
      this.user.email = this.userForm.get('email').value;
      this.user.emailVerified = true;
      this.formSub = this.usapi.patchAttributes(this.user.id, this.user).subscribe(
        () => {
          this.onBack();
        }
      );
    }
  }

  onChangePassword() {
    const oldpass = this.passForm.get('oldpass').value;
    const newpass = this.passForm.get('newpass').value;
    this.cpass = this.usapi.changePassword(oldpass, newpass).subscribe(
      () => {
        this.passok = true,
        this.passnok = false;
        this.message = "Mot de passe modifié !"
      },
      (e) => {
        if(e.message === 'Invalid current password')
          e.message = 'Mot de passe actuel invalide';
        this.passnok = true,
        this.passok = false,
        this.message = "Erreur lors de la modification du mot de passe: "+e.message
      }
    );
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
    if(typeof this.formSub !== 'undefined')
      this.formSub.unsubscribe();
    if(typeof this.cpass !== 'undefined')
      this.cpass.unsubscribe();
  }

  onBack() {
    this.location.back();
  }

}
